import {
  GetShipmentsQuery,
  RoutingJobsQuery,
  ShipmentEdge,
  ShipmentsSorting,
  useGetShipmentsLazyQuery,
  useRoutingJobsLazyQuery,
} from "@api/graphql/generated/generated-types";
import React, { createContext, useContext, useEffect, useState } from "react";
import { SortFieldsType, useTableControl } from "./TableControlContext";
import { useDebounce, useLocalStorage } from "usehooks-ts";
import { makeGetShipmentsVariable } from "../utils/makeGetShipmentsVariable";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { useShipmentFiltersContext } from "./ShipmentFiltersContext";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import { ShipmentRow } from "../types";
import { makeShipmentEdgesToShipmentRow } from "../utils/shipmentRowFormatter";

// Define the context interface
interface ShipmentsCoreDataContextProps {
  shipmentsApiResponse: GetShipmentsQuery | undefined;
  refetchGetShipments: any;
  shipmentsApiLoading: boolean;
  rows: ShipmentRow[];
  onDispatch: (lastChangedShipmentId?: string | null | undefined) => void;
  fetchShipments: () => void;
  indexToRows: object;
  shipmentIdToIndex: object;
  currentPage: number;
  setCurrentPage: (page: number) => void;
  limit: { id: string; label: string };
  setLimit: (label: { id: string; label: string }) => void;
  routingJobsData: RoutingJobsQuery | undefined;
  routingJobsLoading: boolean;
  shipmentsOverviewCounts: any;
  setShipmentsOverviewCounts: React.Dispatch<any>;
  refetchRoutingJobs: any;
}

// the context with a default value
const ShipmentsCoreDataContext =
  createContext<ShipmentsCoreDataContextProps | undefined>(undefined);

type Props = {
  children: React.ReactNode;
};

const DEBOUNCE_TIME = 700;

// the context provider
const ShipmentsCoreDataProvider = ({ children }: Props) => {
  // contexts
  const { showToast } = useCourieStore();
  const { courierId } = useContext(AuthContext);
  const { sortFields, setSortFields } = useTableControl();
  const {
    minDate,
    maxDate,
    selectedPrimaryFilter,
    selectedDriverIdFilter,
    selectedCustomer,
    trackingNumbersFilter,
    displayIdsFilter,
    searchTerm,
  } = useShipmentFiltersContext();
  // states
  const [rows, setRows] = useState<ShipmentRow[]>([]);
  const [indexToRows, setIndexToRows] = useState<object>({});
  const [shipmentIdToIndex, setShipmentIdToIndex] = useState<object>({});
  const debouncedSortFields = useDebounce<SortFieldsType>(
    sortFields,
    DEBOUNCE_TIME
  );
  const debouncedSearchTerm = useDebounce<string>(searchTerm, DEBOUNCE_TIME);
  const debouncedMinDate = useDebounce<Date | undefined>(
    minDate,
    DEBOUNCE_TIME
  );
  const debouncedMaxDate = useDebounce<Date | undefined>(
    maxDate,
    DEBOUNCE_TIME
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [limit, setLimit] = useLocalStorage("ShipmentsTableRowsPerPage", {
    id: "100",
    label: "100",
  });
  const [shipmentsOverviewCounts, setShipmentsOverviewCounts] =
    useState<any>(null);

  const memoizedLimit = React.useMemo(() => limit, [JSON.stringify(limit)]);
  const memoizedCurrentPage = React.useMemo(() => currentPage, [currentPage]);
  // queries
  const [
    getRoutingJobs,
    {
      data: routingJobsData,
      loading: routingJobsLoading,
      error: routingJobsError,
      refetch: refetchRoutingJobs,
    },
  ] = useRoutingJobsLazyQuery({
    pollInterval: 10000,
  });
  const [
    getShipments,
    {
      data: shipmentsApiResponse,
      loading: shipmentsApiLoading,
      error: shipmentsApiError,
      refetch: refetchGetShipments,
    },
  ] = useGetShipmentsLazyQuery({
    fetchPolicy: "no-cache",
  });

  // effects

  useEffect(() => {
    if (courierId) {
      getRoutingJobs({
        variables: {
          courierId,
          start: 0,
          limit: 1,
        },
      });
    }
  }, [courierId, shipmentsApiResponse]);

  useEffect(() => {
    setRows([]);
    setIndexToRows({});
    setShipmentIdToIndex({});
    if (shipmentsApiResponse && shipmentsApiResponse.shipments?.edges) {
      const shipmentRows: ShipmentRow[] = makeShipmentEdgesToShipmentRow(
        shipmentsApiResponse.shipments.edges as ShipmentEdge[]
      );
      const indexToRowsObj: object = {};
      const shipmentIdToIndexObj: object = {};
      setRows(shipmentRows);
      shipmentRows.forEach((row: ShipmentRow, index: number) => {
        indexToRowsObj[index] = row;
        if (row?.id) {
          shipmentIdToIndexObj[row.id] = index;
        }
      });
      setIndexToRows(indexToRowsObj);
      setShipmentIdToIndex(shipmentIdToIndexObj);
    }
    return () => {
      setRows([]);
      setIndexToRows({});
      setShipmentIdToIndex({});
    };
  }, [shipmentsApiResponse]);

  useEffect(() => {
    fetchShipments();
  }, [memoizedLimit, memoizedCurrentPage]);

  useEffect(() => {
    if (shipmentsApiLoading) {
      return;
    }
    fetchShipments();
  }, [debouncedSortFields]);

  useEffect(() => {
    setCurrentPage(0);
    fetchShipments();
  }, [
    selectedPrimaryFilter,
    debouncedMinDate,
    debouncedMaxDate,
    selectedDriverIdFilter,
    selectedCustomer,
    trackingNumbersFilter,
    displayIdsFilter,
    debouncedSearchTerm,
  ]);

  useEffect(() => {
    if (shipmentsApiError || routingJobsError) {
      const error = shipmentsApiError || routingJobsError;
      error && showErrorToast(error, showToast);
      setSortFields([]);
    }
  }, [shipmentsApiError, routingJobsError]);

  const onDispatch = (lastChangedShipmentId?: string | null | undefined) => {
    fetchShipments().then((res) => {
      if (!lastChangedShipmentId) {
        return;
      }
      const data = res.data;
      const shpimentFound = data?.shipments?.edges?.find((shipmentEdge) => {
        return shipmentEdge?.node?.id === lastChangedShipmentId;
      });
      if (shpimentFound && shpimentFound.node?.driverId === null) {
        fetchShipments();
      }
    });
  };

  const fetchShipments = () =>
    getShipments({
      variables: {
        ...makeGetShipmentsVariable(
          courierId!,
          selectedPrimaryFilter,
          minDate,
          maxDate,
          selectedDriverIdFilter ? [selectedDriverIdFilter] : undefined,
          selectedCustomer
        ),
        limit: Number(limit.id),
        after: currentPage * Number(limit.id),
        simpleSearch:
          debouncedSearchTerm.length > 0 ? debouncedSearchTerm : null,
        trackingNumbersFilter: trackingNumbersFilter
          ? trackingNumbersFilter
          : undefined,
        displayIdsFilter: displayIdsFilter ? displayIdsFilter : undefined,
        sortFields:
          sortFields && sortFields.length > 0
            ? (sortFields as ShipmentsSorting[])
            : undefined,
      },
      pollInterval: 10 * 1000,
      fetchPolicy: "network-only",
    });

  return (
    <ShipmentsCoreDataContext.Provider
      value={{
        shipmentsApiResponse,
        refetchGetShipments,
        shipmentsApiLoading,
        rows,
        onDispatch,
        fetchShipments,
        indexToRows,
        shipmentIdToIndex,
        currentPage,
        setCurrentPage,
        limit,
        setLimit,
        routingJobsData,
        routingJobsLoading,
        shipmentsOverviewCounts,
        setShipmentsOverviewCounts,
        refetchRoutingJobs,
      }}
    >
      {children}
    </ShipmentsCoreDataContext.Provider>
  );
};

// the custom hook
const useShipmentsCoreDataContext = () => {
  const context = useContext(ShipmentsCoreDataContext);
  if (context === undefined) {
    throw new Error(
      "useShipmentsCoreDataContext must be used within a ShipmentsCoreDataProvider"
    );
  }
  return context;
};

export { ShipmentsCoreDataProvider, useShipmentsCoreDataContext };
