import {
  OrderPrice,
  OverridePricingDetailMutationVariables,
  PricingAdjustmentUpdate,
  Shipment,
  useOverridePricingDetailMutation,
  PricingAdjustmentCreate,
  PricingAdjustmentType,
  SurchargeApplyType,
  useGetSurchargePricingRuleLazyQuery,
  SurchargePricingRule,
  SurchargeAmountType,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { Input, Label } from "@src/common/components";
import CourieButton from "@src/common/components/Button/Button";
import DollarInput from "@src/common/components/Input/DollarInput";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { roundToTwoDecimalsIfFractional } from "@src/common/lib/NumberUtils";
import { useCourieStore } from "@src/common/lib/store";
import { Fragment, useEffect, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Select, SIZE, Value } from "baseui/select";
import { displaySurchargeAmount } from "@src/pricing/utils/displaySurchargeAmount";

const OverridePricingDetailForm = ({
  shipment,
  onOveridePrice,
  orderPrice,
}: {
  shipment: Shipment;
  onOveridePrice: any;
  orderPrice: OrderPrice;
}) => {
  const { courierId } = useContext(AuthContext);
  const { showToast } = useCourieStore();
  const { handleSubmit, setValue, control, watch } = useForm();
  const [getSurchargePricingRule, { loading: surchargePricingRuleLoading }] =
    useGetSurchargePricingRuleLazyQuery();
  const [overridePricingDetailMutation, { data, loading, error }] =
    useOverridePricingDetailMutation();
  const [surchargePricingRules, setSurchargePricingRules] = useState<
    SurchargePricingRule[]
  >([]);
  const [selectedValues, setSelectedValues] = useState<Value[]>([]);
  const selectedPricingListId = shipment?.order.endCustomer.pricingList?.id;

  const customSurchargeAdjustments = orderPrice.customSurchargeAdjustments;

  useEffect(() => {
    if (surchargePricingRules.length > 0) {
      setSelectedValues([
        [
          {
            label: surchargePricingRules[0].name,
            id: surchargePricingRules[0].id,
          },
        ],
      ]);
    }
  }, [surchargePricingRules]);

  useEffect(() => {
    if (
      shipment &&
      shipment.waitTimeInMinutes !== undefined &&
      shipment.waitTimeInMinutes !== null
    ) {
      const waitTimeInMinutes = shipment.waitTimeInMinutes;
      const roundedWaitTimeInMinutes =
        roundToTwoDecimalsIfFractional(waitTimeInMinutes);
      setValue("unfreeWaitTimeInMinutes", roundedWaitTimeInMinutes.toString());
    }
  }, [shipment, setValue]);

  useEffect(() => {
    if (!customSurchargeAdjustments) {
      return;
    }
    // Set default values for custom surcharge adjustments
    customSurchargeAdjustments.forEach((adjustment, index) => {
      setValue(
        `customSurchargeAdjustments[${index}].amount`,
        adjustment.amount
      );
    });
  }, [customSurchargeAdjustments, setValue]);

  useEffect(() => {
    if (courierId) {
      getSurchargePricingRule({
        variables: {
          courierId: courierId,
          surchargeApplyType: SurchargeApplyType.ManualOnly,
          defaultPricingListOnly: selectedPricingListId ? false : true,
          amountTypes: SurchargeAmountType.PerWaitTimeMinutes,
          pricingListIdsFilter: selectedPricingListId && [
            selectedPricingListId,
          ],
        },
      }).then((res) => {
        if (res.data) {
          const surchargePricingRules = res.data.surchargePricingRules.map(
            (rule) => ({
              ...rule,
              amountDisplay: displaySurchargeAmount(
                rule.amountType,
                rule.amount,
                rule.percent
              ),
            })
          );
          setSurchargePricingRules(
            surchargePricingRules as SurchargePricingRule[]
          );
        }
      });
    }
  }, [courierId]);

  const handleSelectChange = (params: any, index: number) => {
    const newSelectedValues = [...selectedValues];
    newSelectedValues[index] = params.value;
    setSelectedValues(newSelectedValues);
  };

  const onSubmit = (formData) => {
    if (!customSurchargeAdjustments) {
      return;
    }
    const adjustmentUpdates: PricingAdjustmentUpdate[] | undefined =
      formData.customSurchargeAdjustments?.map((item, index) => {
        return {
          id: customSurchargeAdjustments[index].id,
          amountTotal: item.amount,
        };
      });

    const adjustmentsToAdd: PricingAdjustmentCreate[] =
      selectedValues[0].length > 0
        ? selectedValues.map((value) => {
            return {
              type: PricingAdjustmentType.RuleBased,
              surchargePricingRuleId: value[0].id as string,
            };
          })
        : [];
    const variables: OverridePricingDetailMutationVariables = {
      shipmentId: shipment.id,
      unfreeWaitTimeInMinutes:
        formData.unfreeWaitTimeInMinutes === 0
          ? 0
          : parseInt(formData.unfreeWaitTimeInMinutes, 10),
      adjustmentUpdates,
      adjustmentsToAdd,
    };
    overridePricingDetailMutation({ variables })
      .then(() => {
        onOveridePrice && onOveridePrice();
        showToast({
          type: "success",
          message: "Pricing details updated successfully",
        });
      })
      .catch((error) => {
        showErrorToast(error, showToast);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-2">
      {customSurchargeAdjustments &&
        customSurchargeAdjustments.map((adjustment, index) => (
          <Fragment key={adjustment.id}>
            <Label
              htmlFor={`customSurchargeAdjustments[${index}].amount`}
              className="text-xs text-gray-800"
            >
              {adjustment.surchargePricingRule?.name}
            </Label>
            <Controller
              name={`customSurchargeAdjustments[${index}].amount`}
              control={control}
              render={({ field }) => (
                <DollarInput
                  size="mini"
                  error={error ? true : false}
                  dollar={Number(field.value) || 0}
                  setDollar={(dollar) => field.onChange(dollar)}
                />
              )}
            />
          </Fragment>
        ))}
      <div className="flex flex-col gap-2">
        <div>
          <Label
            htmlFor="unfreeWaitTimeInMinutes"
            className="text-xs text-gray-800 truncate"
          >
            Wait Time Unfree Minutes
          </Label>
          <Controller
            name={"unfreeWaitTimeInMinutes"}
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <Input
                  size="mini"
                  type="number"
                  error={!!error}
                  value={field.value || ""}
                  onChange={(event) => {
                    if (parseInt(event.currentTarget.value) === 0) {
                      setSelectedValues([]);
                      if (surchargePricingRules.length > 0) {
                        setSelectedValues([
                          [
                            {
                              label: surchargePricingRules[0].name,
                              id: surchargePricingRules[0].id,
                            },
                          ],
                        ]);
                      }
                    }
                    field.onChange(event.currentTarget.value);
                  }}
                  onBlur={field.onBlur}
                />
              );
            }}
          />
        </div>
        {watch("unfreeWaitTimeInMinutes") > 0 && (
          <div>
            <Label
              htmlFor="adjustmentToAdd.amount"
              className="text-xs text-gray-800"
            >
              Surcharge
            </Label>
            <Select
              isLoading={surchargePricingRuleLoading}
              clearable={true}
              size={SIZE.mini}
              options={surchargePricingRules.map((rule) => ({
                label: rule.name,
                id: rule.id,
              }))}
              searchable={false}
              value={selectedValues[0]}
              placeholder="Select rule"
              onChange={(params: any) => {
                handleSelectChange(params, 0);
              }}
            />
          </div>
        )}
      </div>

      <div className="flex justify-end mt-2">
        <CourieButton size="xs" type="submit" isProcessing={loading}>
          Save Changes
        </CourieButton>
      </div>
    </form>
  );
};

export default OverridePricingDetailForm;
